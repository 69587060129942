import { Modal, message } from "antd";
import React, { useState } from "react";
import {
  getUserData,
  signupUser,
  signupUserFolder,
} from "../../../../SupabaseServices/UserDatabase";
import { useAuth } from "../../../../hooks/useAuth";
import { User } from "@supabase/supabase-js";
import { supabase } from "../../../../Config/SupabaseClient";
import Papa from "papaparse";
import axios from "axios";

interface CustomFileInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  webkitdirectory?: string;
  mozdirectory?: string;
  directory?: string;
}
const NewCreatorUpload = (props: any) => {
  const { showNewCreatorModal, user_id } = props;
  const { user } = useAuth();
  const creatorUserId = "";
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [messageApi, contextHolder] = message.useMessage();

  const success = (msg: any) => {
    messageApi.open({
      type: "success",
      content: msg,
    });
  };
  const error = (msg: any) => {
    messageApi.open({
      type: "error",
      content: msg,
    });
  };
  const handleCancel = () => {
    props.setShowNewCreatorModal(false);
    setSelectedFiles([]);
  };

  const handleFileChange = async (event: any) => {
    const files = event.target.files;
    const creatorName = files[0].webkitRelativePath.split("/")[0];
   
    setSelectedUser(creatorName);
    setSelectedFiles([...selectedFiles, ...files]);

  };

  const uploadFolder = async () => {
    const res: any = await signupUserFolder(
      selectedUser,
      creatorUserId,
      user as User
    );
    if (res.error) {
      // setError(true);
      // setErrorMsg(res.message);
      // setLoading(false);
      alert("Error Adding Creator!");
      return;
    } else {
      // success("Creator Added Successfully!")
      alert("User created");

      props.fetchUsers();
      // props.setShowNewCreatorModal(false);
      // props.setSelectedUser(username)
      // props.setIsModalOpen(true)
    }

    const uploadData: any = [];
    const csvFiles: any = [];
    selectedFiles.map((file: any) => {
      if (file.name.toLowerCase().endsWith(".csv")) {
        csvFiles.push(file);
      } else {
        uploadData.push(file);
      }
    });
    console.log("diff", csvFiles, uploadData);
    let progressCount = 1;

    const userData: any = await getUserData(selectedUser);

    let urls: any = [];

    try {
      for (const file of uploadData) {
        const post_id = file.name.split("_")[1].replace(/\.[^/.]+$/, "");
        const mediaType = file.type.split("/")[0];
        const mediaExt = file.type.split("/")[1];

        // Upload file
        try {
          const { data, error }: any = await supabase.storage
            .from("content")
            .upload(
              `${selectedUser}/${mediaType}${post_id}.${mediaExt}`,
              file,
              {
                contentType: `${file.type}`,
              }
            );

          if (error) {
            if (error.error === "Duplicate") {
              console.log("post already stored", progressCount, post_id);
              const { data: urlData } = await supabase.storage
                .from("content")
                .getPublicUrl(
                  `${selectedUser}/${mediaType}${post_id}.${mediaExt}`
                );

              urls.push({
                id: post_id,
                url: urlData.publicUrl,
              });
            } else {
              console.log("error in uploading", error, progressCount, post_id);
            }

            progressCount += 1;
            continue; // Skip to the next file if upload fails
          } else {
            console.log("upload to storage", progressCount);
          }
        } catch (error) {
          console.log("error in uploading", error);
          progressCount += 1;
          continue;
        }

        // Fetch URL
        try {
          const { data: urlData } = await supabase.storage
            .from("content")
            .getPublicUrl(`${selectedUser}/${mediaType}${post_id}.${mediaExt}`);

          urls.push({
            id: post_id,
            url: urlData.publicUrl,
          });
        } catch (urlError: any) {
          console.error(
            `Failed to fetch URL for ${file.name}: ${urlError.message}`
          );
          continue; // Skip to the next file if URL fetch fails
        }
        progressCount += 1;
      }

      // Process URLs and perform other operations
      // ...

      console.log("All uploads and URL fetches completed:", urls);
    } catch (error) {
      console.error("Unexpected error", error);
    }

    if (csvFiles[0]) {
      console.log("cc", csvFiles[0]);
      try {
        const result: any = await new Promise((resolve, reject) => {
          Papa.parse(csvFiles[0], {
            complete: (result) => resolve(result),
            header: true,
            error: (error) => {
              console.error("Error parsing CSV file:", error);
              reject(error); // Make sure to reject the promise after logging the error
            },
          });
        });

        let idx = 1;

        // Remove rows with no post_id
        result.data = result.data.filter(
          (row: any) =>
            row.post_id !== undefined &&
            row.post_id !== null &&
            row.post_id !== ""
        );

        console.log("After deletion - total:", result.data, result.data.length);
        console.log("Wait for 1 minute...");
        const delayDuration = 1 * 60 * 1000; // 2 minutes in milliseconds

        // Introduce a 2-minute delay before the next asynchronous operation
        // await new Promise((resolve) => setTimeout(resolve, delayDuration));

        console.log("Proceeding with the next steps...");
        await result.data.reduce(
          async (previousPromise: any, post: any, idx: any) => {
            await previousPromise;

            return new Promise(async (resolve) => {
              try {
                if (idx - 1 >= urls.length) {
                  console.log(
                    "Index reached maximum value. Stopping the process."
                  );
                  resolve({});
                  return;
                }
                await new Promise((innerResolve) => {
                  console.log("wait 400");
                  setTimeout(innerResolve, 400);
                });

                if (post.post_id) {
                  const post_id = post.post_id;
                  const post_date = post.filename.split("_")[0];
                  const caption = post.description;
                  const like_count = post.likes;
                  const comments_count = post.comments;

                  const foundElement = urls.find(
                    (element: any) => element.id === post_id
                  );

                  if (foundElement) {
                    const { data: storedPost } = await supabase
                      .from("content_instagram")
                      .select("*")
                      .eq("post_id", foundElement.id)
                      .eq("user_id", userData.user_id);

                    if (!storedPost || storedPost.length === 0) {
                      const { data: content } = await supabase
                        .from("content_instagram")
                        .insert([
                          {
                            post_id,
                            user_id: userData.user_id,
                            post_date,
                            caption: caption || " ",
                            media_url: foundElement.url,
                            type: foundElement.url.includes("video")
                              ? "REELS"
                              : "FEED",
                            storage_url: foundElement.url,
                            like_count,
                            comments_count,
                          },
                        ])
                        .select("*");

                      if (content && content.length) {
                        const { data, error } = await supabase
                          .from("content_new")
                          .insert([
                            {
                              post_id,
                              user_id: userData.user_id,
                              caption: caption || " ",
                              media_url: foundElement.url,
                              type: foundElement.url.includes("video")
                                ? "REELS"
                                : "FEED",
                              platform: "instagram",
                              post_date,
                              raw_content_id: content[0].id,
                              metadata: JSON.stringify({
                                like_count: like_count,
                                comments_count: comments_count,
                              }),
                              combined_text: caption || "",
                            },
                          ]);

                        console.log("post uploaded", idx, data, post_id, error);
                      }
                    } else {
                      console.log("Post already stored:", idx, post_id);
                    }
                  }
                } else {
                  console.log("no post id at index:", idx);
                }

                resolve({});
              } catch (error) {
                console.error("Error processing CSV data:", error);
                resolve({});
              }
            });
          },
          Promise.resolve()
        );

        console.log("All async operations inside the loop completed");

        console.log("wait...");
        const time = uploadData.length * 200;
        console.log(time);
        setTimeout(async () => {
          const thumbnailUrl = `https://dev.thesearchbubble.com/fetch/thumbnail`;
          // const thumbnailUrl = `http://localhost:3001/fetch/thumbnail`;

          const resThumbnail = await axios.post(
            thumbnailUrl,
            { id: userData.user_id, user: userData.username, from: "content" },
            {
              headers: {
                "content-type": "application/json",
              },
            }
          );
          console.log("res", resThumbnail.data);
          if (resThumbnail.data === "done") {
            console.log(
              "Processing your data : Thumbnails, tags, carausel, embeddings"
            );
            console.log("Please wait !!!");
            setTimeout(async () => {
              const { data: update, error } = await supabase
                .from("user")
                .update({ show_instagram: true, is_instagram_on: true })
                .eq("user_id", userData.user_id);
            }, uploadData.length * 100);
            console.log("updates user");
          }
        }, time);
      } catch (error) {
        console.error("Error parsing CSV file:", error);
      }
    }

    try {
      const { data: index } = await supabase
        .from("user")
        .update({ is_indexed: true })
        .eq("username", selectedUser);
    } catch (error) {
      console.error("Error updating user indexing status:", error);
    }
  };

  return (
    <div>
      <Modal
        title="Create new creator"
        open={showNewCreatorModal}
        // onOk={handleOk}
        onCancel={handleCancel}
        okText="Create"
        footer={null}
      >
        <p>Upload folder </p>
        <input
          type="file"
          multiple
          {...({
            webkitdirectory: "",
            mozdirectory: "",
            directory: "",
          } as CustomFileInputProps)}
          onChange={handleFileChange}
        />
        <button onClick={uploadFolder}>Upload folder</button>
      </Modal>
    </div>
  );
};

export default NewCreatorUpload;
