import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  fetchIncludedUsername,
  fetchIncludedUsers,
} from "../../../SupabaseServices/UserDatabase";
import { useLoadProductBankPages } from "./useLoadProductBankPages";
import { useLoadProductBankContent } from "./useLoadProductBankContent";
import { Button, Input, Modal, Pagination, Upload, UploadProps } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { supabase } from "../../../Config/SupabaseClient";
import { addProduct } from "../../../SupabaseServices/ProductBankDatabase";
import ProductContent from "./ProductContent";
import PuffLoader from "react-spinners/PuffLoader";
import { Select, Space } from "antd";

const POSTS_PER_PAGE = 25;
const ProductBank = () => {
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [image, setImage] = useState("");
  const [platform, setPlatform] = useState("");
  const [category, setCategory] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const platformItems = [
    { value: "Amazon", label: "Amazon" },
    { value: "LTK", label: "LTK" },
    { value: "ShopMy", label: "ShopMy" },
    { value: "instagram", label: "instagram" },
    // { value: 'instagram', label: 'instagram' },
  ];
  const handleOk = async () => {
    await addProduct(name, link, image, selectedIds[0], platform, category);
    refetchCount();
    refetch();
    setName("");
    setLink("");
    setImage("");
    setPlatform("");
    setCategory("");
    setFileList([]);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setName("");
    setLink("");
    setImage("");
    setPlatform("");
    setCategory("");
    setFileList([]);
    setIsModalOpen(false);
  };
  const [usernames, setUsernames] = useState<any>([]);
  const [selectedCreator, setSelectedCreator] = useState<any>();
  const [selectedIds, setSelectedIds] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);

  useQuery({
    queryFn: () => {
      fetchUsers();
    },
    refetchOnMount: true,
  });
  async function fetchUsers() {
    const users = await fetchIncludedUsername();
    const userIds = await fetchIncludedUsers();
    setSelectedIds(userIds);
    setSelectedCreator(users);
    setUsernames(users);
  }
  console.log(usernames, selectedIds);
  const { data: pages, refetch: refetchCount } = useLoadProductBankPages(
    selectedIds?.[0] || "",

    POSTS_PER_PAGE,
    "",
    usernames?.[0] || ""
  );
  console.log("pages", pages);
  const {
    data: posts,
    isLoading: postsLoading,
    isError,
    error,
    refetch,
  } = useLoadProductBankContent(
    selectedIds?.[0] || "",
    usernames?.[0] || "",
    currentPage,
    POSTS_PER_PAGE,
    pages?.totalPages
  );
  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };
  const handleInputChange = (value: string, setter: Function) => {
    setter(value);
  };
  const uploadProps: UploadProps<any> = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    headers: {
      authorization: "authorization-text",
    },
    async onChange(info: any) {
      console.log("use", usernames[0], info, name);
      info.file.status = "uploading";

      let fileList: any = [...info.fileList];

      fileList = fileList.slice(-1);

      // 2. Read from response and show file link
      fileList = fileList.map((file: any) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
          file.staus = "uploading";
        }
        return file;
      });
      setFileList(fileList);

      setTimeout(() => {
        info.file.status = "done";
      }, 0);

      const { data: highlightslist }: any = await supabase.storage
        .from("product_bank")
        .list(`${usernames?.[0]}/${name}`, { limit: 5000 });
      for (let item of highlightslist) {
        await supabase.storage
          .from("product_bank")
          .remove([`${usernames?.[0]}/${name}/${item.name}`]);
      }
      const post_id = info.file.name.split("_")[0];
      const { data, error }: any = await supabase.storage
        .from("product_bank")
        .upload(
          `${usernames?.[0]}/${name}/${info.file.type.split("/")[0]}${post_id}`,
          info.file.originFileObj,
          {
            contentType: `${info.file.type}`,
          }
        );

      var { data: url }: any = await supabase.storage
        .from("product_bank")
        .getPublicUrl(
          `${usernames?.[0]}/${name}/${info.file.type.split("/")[0]}${post_id}`
        );
      setImage(url.publicUrl);
      handleInputChange(url.publicUrl, setImage);
      // props.handleData(url.publicUrl, name, postlink,"content");
      if (info.file.status === "done") {
      } else if (info.file.status === "error") {
      }
    },
  };
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    setPlatform(value);
  };
  return (
    <>
      <Button className="add-product" onClick={showModal}>
        Add Product
      </Button>
      <Modal
        title="Add Product To Product Bank"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Add"
        okButtonProps={{ disabled: !(name && link && image) }}
      >
        <label className="font-bold">Product Name</label>
        <Input
          className="mb-2"
          placeholder="Enter Product Name"
          value={name}
          onChange={(e: any) => {
            handleInputChange(e.target.value, setName);
          }}
        />
        <label className="font-bold">Product Link</label>
        <Input
          className="mb-2"
          placeholder="Enter Product Link"
          value={link}
          onChange={(e: any) => {
            handleInputChange(e.target.value, setLink);
          }}
        />
        <label className="font-bold">Product Category</label>

        <Input
          className="mb-2"
          placeholder="Enter Category"
          value={category}
          onChange={(e: any) => {
            handleInputChange(e.target.value, setCategory);
          }}
        />
        <label className="font-bold">Platform</label>
        <Select
          defaultValue={platform }
          placeholder="Select Platform"
          style={{ width: 120 }}
          onChange={handleChange}
          options={platformItems}
        />
        {/* <Input
          className="mb-2"
          placeholder="Enter Platform"
          value={platform}
          onChange={(e: any) => {
            handleInputChange(e.target.value, setPlatform);
          }}
        /> */}

        <div className="modal-image-upload">
          <img src={image} className="img-style" />
          <Upload
            {...uploadProps}
            maxCount={1}
            className="img-upload"
            showUploadList={fileList.length > 0 ? true : false}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </div>
      </Modal>

      <div className="d-side">
        <div>
          {selectedCreator && selectedCreator.length && (
            <>Selected Creator :- {usernames[0]}</>
          )}
        </div>
        <div className="product-bank-content">
          {selectedCreator && selectedCreator.length ? (
            <>
              <ProductContent
                username={usernames[0]}
                user_id={selectedIds[0]}
                refetch={refetch}
                posts={posts}
                currentPage={currentPage}
              />

              <Pagination
                showQuickJumper
                current={currentPage}
                pageSize={POSTS_PER_PAGE}
                total={pages?.feedCount}
                onChange={handlePageChange}
                showSizeChanger={false}
                className="pagination"
              />
            </>
          ) : (
            //

            <PuffLoader
              color="#5274f9"
              //   loading={userLoading}
              className="loader"
              size={150}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ProductBank;
