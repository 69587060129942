import { Button, Input, Modal, Upload, UploadProps } from "antd";
import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { supabase } from "../../../Config/SupabaseClient";

const ProductDetails = (props: any) => {
  const { username, user_id, isProductModalOpen, post_id, post } = props;
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [fileList,setFileList]=useState([])
  const showModal = () => {
    props.setIsProductModalOpen(true);
  };
  useEffect(() => {
    if (
      post.product_image &&
      post.product_image !== "" &&
      post.product_name &&
      post.product_name != ""
    ) {
      setName(post?.product_name);
      setUrl(post?.product_image);
    }else{
      setName("")

setUrl("")    }
  }, [isProductModalOpen]);
  const handleOk = async () => {
    const { data: insta, error: instaError } = await supabase
      .from("content_instagram")
      .update({ product_image: url, product_name: name })
      .eq("post_id", post_id)
      .eq("user_id", user_id);
    const { data: newdata, error: newError } = await supabase
      .from("content_new")
      .update({ product_image: url, product_name: name })
      .eq("post_id", post_id)
      .eq("user_id", user_id);
    const { data: user, error: userError } = await supabase
      .from(`${username}`)
      .update({ product_image: url, product_name: name })
      .eq("post_id", post_id)
      .eq("user_id", user_id);
    props.setIsProductModalOpen(false);
  };

  const handleCancel = () => {
    setUrl("");
    setName("");
    props.setIsProductModalOpen(false);
  };
  const uploadProps: UploadProps<any> = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    headers: {
      authorization: "authorization-text",
    },
    async onChange(info: any) {
      info.file.status = "uploading";

      let fileList: any = [...info.fileList];

      fileList = fileList.slice(-1);

      // 2. Read from response and show file link
      fileList = fileList.map((file: any) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
          file.staus = "uploading";
        }
        return file;
      });
      setFileList(fileList);

      setTimeout(() => {
        info.file.status = "done";
      }, 500);

      // const { data: highlightslist }: any = await supabase.storage
      //   .from("products")
      //   .list(`${username}/${name}`, { limit: 5000 });
      // for (let item of highlightslist) {
      //   await supabase.storage
      //     .from("products")
      //     .remove([`${username}/${name}/${item.name}`]);
      // }
      const post_id = info.file.name.split("_")[0];
      const { data, error }: any = await supabase.storage
        .from("products")
        .upload(
          `${username}/${name}/${info.file.type.split("/")[0]}${post_id}`,
          info.file.originFileObj,
          {
            contentType: `${info.file.type}`,
          }
        );

      var { data: url }: any = await supabase.storage
        .from("products")
        .getPublicUrl(
          `${username}/${name}/${info.file.type.split("/")[0]}${post_id}`
        );
      setUrl(url.publicUrl);
      //   props.handleData(url.publicUrl, name, postlink,"content");
      if (info.file.status === "done") {
      } else if (info.file.status === "error") {
      }
    },
  };
  const handleInputChange = (value: string, setter: Function) => {
    setter(value); // Set the input value
    // setIsButtonDisabled(!value || !details); // Disable button if either name or details is empty
  };
  return (
    <>
      <Modal
        title="Basic Modal"
        open={isProductModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <img src={url} className="w-20"/>
        <p>{name}</p>
        <Input
          placeholder="Enter product name"
          value={name}
          onChange={(e: any) => handleInputChange(e.target.value, setName)}
          className="mb-20"
        />
        <Upload {...uploadProps} multiple={false} maxCount={1} showUploadList={fileList.length>0?true:false}>
          <Button icon={<UploadOutlined />} className="mb-15">
            Click to Upload
          </Button>
        </Upload>
      </Modal>
    </>
  );
};

export default ProductDetails;
