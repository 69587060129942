import { supabase } from "../Config/SupabaseClient";

export async function addProduct(
  name: any,
  url: any,
  image: any,
  user_id: any,
  platform: any,
  category: any
) {
  function generateRandom12DigitNumber() {
    // Generate a random number between 100000000000 and 999999999999
    return (
      Math.floor(Math.random() * (9999999999999999 - 1000000000000000 + 1)) +
      1000000000000000
    );
  }

  // Example usage:
  let randomNumber = generateRandom12DigitNumber();
  console.log(randomNumber);
  const { data, error } = await supabase.from("product_bank").insert([
    {
      name,
      url,
      image,
      user_id,
      product_id: randomNumber,
      platform,
      category,
    },
  ]);
  console.log("ffdf", data, error);
  if (error !== null) {
    function generateRandom12DigitNumber() {
      // Generate a random number between 100000000000 and 999999999999
      return (
        Math.floor(Math.random() * (9999999999999999 - 1000000000000000 + 1)) +
        1000000000000000
      );
    }

    // Example usage:
    let randomNumber = generateRandom12DigitNumber();
    console.log(randomNumber);
    const { data, error } = await supabase.from("product_bank").insert([
      {
        name,
        url,
        image,
        user_id,
        product_id: randomNumber,
        platform,
        category,
      },
    ]);
  }
}

export async function addProductBulk(
  username: any,
  user_id: any,
  name: any,
  url: any,
  image: any,
  tags: any,
  category: any,
  platform: any,
  price:any
) {
  console.log("fd", image);
  const categoryUpload= category.split(" ")[0];
  if (image && image != "") {
    let response: any;
    try {
      response = await fetch(image);
    } catch (err) {
      console.log("/download.jpeg");
      response = await fetch("/download.jpeg");
    }

    const imageBlob = await response.blob();
    const mediaType = imageBlob.type.split("/")[0];
    const mediaExt = imageBlob.type.split("/")[1];
    function generateRandom12DigitNumber() {
      // Generate a random number between 100000000000 and 999999999999
      return (
        Math.floor(Math.random() * (9999999999999999 - 1000000000000000 + 1)) +
        1000000000000000
      );
    }

    // Example usage:
    let post_id = generateRandom12DigitNumber();
    const { data, error }: any = await supabase.storage
      .from("product_bank")
      .upload(`${username}/${categoryUpload}/${mediaType}${post_id}`, imageBlob, {
        contentType: `${imageBlob.type}`,
      });
    if (error != null) {
      function generateRandom12DigitNumber() {
        // Generate a random number between 100000000000 and 999999999999
        return (
          Math.floor(
            Math.random() * (9999999999999999 - 1000000000000000 + 1)
          ) + 1000000000000000
        );
      }

      // Example usage:
      let post_id = generateRandom12DigitNumber();
      const { data, error }: any = await supabase.storage
        .from("product_bank")
        .upload(`${username}/${categoryUpload}/${mediaType}${post_id}`, imageBlob, {
          contentType: `${imageBlob.type}`,
        });
    }

    var { data: imageUrl }: any = await supabase.storage
      .from("product_bank")
      .getPublicUrl(`${username}/${categoryUpload}/${mediaType}${post_id}`);

    const { data: store, error: storeError } = await supabase
      .from("product_bank")
      .insert([
        {
          name,
          url,
          image: imageUrl.publicUrl,
          user_id,
          product_id: post_id,
          category: category,
          tagg: tags,
          platform,
          price
        },
      ]);
  }
}
