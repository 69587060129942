import { Button, Result } from "antd";
import React, { useState } from "react";
import { getUserData } from "../../../../SupabaseServices/UserDatabase";
import Papa from "papaparse";
import { supabase } from "../../../../Config/SupabaseClient";
import axios from "axios";

export const TwitterUpload = (props: any) => {
  const { username } = props;
  console.log("us", username);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);

  const handleFolderChange = async (event: any) => {
    const files = Array.from(event.target.files);

    const filteredFiles = files.filter((file: any) => file.size > 0);
    console.log("fdf", filteredFiles);
    setSelectedFiles(filteredFiles);
  };

  const uploadFolder = async () => {
    const uploadData: any = [];
    const csvFiles: any = [];
    selectedFiles.map((file: any) => {
      if (file.name.toLowerCase().endsWith(".csv")) {
        csvFiles.push(file);
      } else {
        uploadData.push(file);
      }
    });
    console.log("diff", csvFiles, uploadData);
    // let progressCount = 1;

    const userData: any = await getUserData(username);

    if (csvFiles[0]) {
      console.log("cc", csvFiles[0]);
      //   try {
      const result: any = await new Promise((resolve, reject) => {
        Papa.parse(csvFiles[0], {
          complete: (result) => resolve(result),
          header: true,
          error: (error) => {
            console.error("Error parsing CSV file:", error);
            reject(error); // Make sure to reject the promise after logging the error
          },
        });
      });

      console.log("fdf", result);
      result.data = result.data.filter(
        (row: any) =>
          row.tweet_id !== undefined &&
          row.tweet_id !== null &&
          row.tweet_id !== ""
      );

        for (let [i, res] of result.data.entries()) {
          console.log(i, res);
          if (res.tweet_id) {
            const { data: storedPost } = await supabase
              .from("content_twitter")
              .select("*")
              .eq("tweet_id", res.tweet_id)
              .eq("user_id", userData.user_id);

            if (!storedPost || storedPost.length === 0) {
              const { data: content } = await supabase
                .from("content_twitter")
                .insert([
                  {
                    tweet_id: res.tweet_id,
                    user_id: userData.user_id,
                    post_date: res.created_at,
                    tweet: res.text || " ",
                    media_url: res.media_url,
                    type: "TWEET",
                    storage_url: res.media_url,
                    permalink: res.urls,
                    hashtags: res.hashtags,
                    metadata: JSON.stringify({
                      bookmark_count: res.bookmark_count,
                      client: res.client,
                      favorite_count: res.favorite_count,
                      reply_count: res.reply_count,
                      retweet_count: res.retweet_count,
                      view_count: res.view_count,
                    }),
                  },
                ])
                .select("*");

              if (content && content.length) {
                const { data, error } = await supabase
                  .from("content_new")
                  .insert([
                    {
                      post_id: res.tweet_id,
                      user_id: userData.user_id,
                      caption: res.text || " ",
                      media_url: res.media_url,
                      post_date: res.created_at,

                      type: "TWEET",
                      permalink: res.urls,
                      metadata: JSON.stringify({
                        bookmark_count: res.bookmark_count,
                        client: res.client,
                        favorite_count: res.favorite_count,
                        reply_count: res.reply_count,
                        retweet_count: res.retweet_count,
                        view_count: res.view_count,
                      }),
                      platform: "twitter",
                      raw_content_id: content[0].id,

                      combined_text: res.text || "" + " " + res.hashtags,
                    },
                  ]);

                console.log("tweet uploaded", i, data, res.tweet_id, error);
              }
            } else {
              console.log("tweet already stored:", i, res.tweet_id);
            }
          }
        }
    }

    console.log("All async operations inside the loop completed");

    console.log("wait...");
    const time = uploadData.length * 1000;
    console.log(time);
    setTimeout(async () => {
      const thumbnailUrl = `https://dev.thesearchbubble.com/fetch/thumbnail`;
      // const thumbnailUrl = `http://localhost:3001/fetch/thumbnail`;

      const resThumbnail = await axios.post(
        thumbnailUrl,
        { id: userData.user_id, user: userData.username, from: "content" },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );
      console.log("res", resThumbnail.data);
      if (resThumbnail.data === "done") {
        console.log(
          "Processing your data : Thumbnails, tags, carausel, embeddings"
        );
        console.log("Please wait !!!");
        setTimeout(async () => {
            const { data: update, error } = await supabase
            .from("user")
            .update({ show_twitter: true, is_twitter_on: true })
            .eq("user_id", userData.user_id);
        }, uploadData.length * 100);
        console.log("updates user");
      }
    }, time);
  
  };

 
  return (
    <div>
      {" "}
      <h5>Upload Twitter Data</h5>
      <h6>Upload file</h6>
      <input type="file" multiple onChange={handleFolderChange} />
      <Button
        type="primary"
        className="primary-color"
        onClick={uploadFolder}
      >
        Upload
      </Button>
    </div>
  );
};
