import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  fetchIncludedUsername,
  fetchIncludedUsers,
} from "../../../SupabaseServices/UserDatabase";
import { Card, Col, MenuProps, Row, Space, Spin } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import DropdownComponent from "../../../Components/Dropdown/DropdownComponent";
import "./CompanyAnalytics.css";
import useCompanyAnalytics from "./useCompanyAnalytics";
import dayjs from "dayjs";
import DateSelector from "./DateSelector/DateSelector";
import { StaticVariables } from "../../../StaticVariables";

const CompanyAnalytics = () => {
  const liveCreators = ["shop.with.caroline", "thebluewallstudio"];
  const [usernames, setUsernames] = useState<any>([]);
  const [selectedCreator, setSelectedCreator] = useState<any>();
  const [selectedIds, setSelectedIds] = useState<any>();
  const [showComparison, setShowComparison] = useState(false);
  useQuery({
    queryFn: () => {
      fetchUsers();
    },
    refetchOnMount: true,
  });

  async function fetchUsers() {
    const users = await fetchIncludedUsername();
    const userIds = await fetchIncludedUsers();

    setSelectedIds(userIds);
    setSelectedCreator(users);
    setUsernames(users);
  }
  const items = [
    {
      label: "All",
      creators: ["shop.with.caroline", "thebluewallstudio"],
      ids: [
        "8c5e4f5d-51ac-41d8-b8c6-dbd2b080a121",
        "c1fb7a69-83ae-4af8-b65c-fea3ac35c6bf",
      ],
      key: "1",
      icon: <UserOutlined />,
    },
    {
      label: "shop.with.caroline",
      ids: ["8c5e4f5d-51ac-41d8-b8c6-dbd2b080a121"],
      creators: ["shop.with.caroline"],

      key: "2",
      icon: <UserOutlined />,
    },
    {
      label: "thebluewallstudio",
      ids: ["c1fb7a69-83ae-4af8-b65c-fea3ac35c6bf"],
      creators: ["thebluewallstudio"],

      key: "3",
      icon: <UserOutlined />,
    },
  ];
  const [selectedUser, setSelectedUser] = useState<any>(items?.[0]);
  const [startDate, setStartDate] = useState(
    dayjs().subtract(100000, "day").format("YYYY-MM-DD")
  );
  const endDate = dayjs(); //today
  const [previousStartDate, setPreviousStartDate] = useState(
    dayjs().subtract(100000, "day").format("YYYY-MM-DD")
  );
  const [previousEndDate, setPreviousEndDate] = useState(
    dayjs().format("YYYY-MM-DD")
  ); // today
  const [isYesterday, setIsYesteday] = useState(false);
  const [daysNumber, setDays] = useState<any>();
  function updateDates(days: number) {
    setDays(days);
    if (days === 1) {
      setIsYesteday(true);
      setPreviousStartDate(dayjs().subtract(2, "day").format("YYYY-MM-DD")); // Start date for yesterday would be the day before yesterday
      setPreviousEndDate(dayjs().subtract(1, "day").format("YYYY-MM-DD"));
    } else {
      setIsYesteday(false);
    }
    if (days > 0) {
      setStartDate(dayjs().subtract(days, "day").format("YYYY-MM-DD"));
      const startOfPreviousPeriod = dayjs().subtract(days * 2, "day"); // Start date of the previous period
      const endOfPreviousPeriod = dayjs().subtract(days, "day"); // End date of the previous period

      setPreviousStartDate(startOfPreviousPeriod.format("YYYY-MM-DD"));
      setPreviousEndDate(endOfPreviousPeriod.format("YYYY-MM-DD"));
    } else {
      setShowComparison(false)
      setStartDate(dayjs().subtract(100000, "day").format("YYYY-MM-DD"));
      setPreviousStartDate(
        dayjs().subtract(100000, "day").format("YYYY-MM-DD")
      );
      setPreviousEndDate(dayjs().format("YYYY-MM-DD"));
    }
  }
  const {
    loadingUniqueUsers,
    uniqueUsers,
    loadingPageViews,
    pageViews,
    loadingSearchesCount,
    searchesCount,
    shoppableClickCount,
    loadingshoppableClickCount,
    previousuniqueUsers,
    loadingpreviousUniqueUsers,
    previouspageViews,
    loadingpreviousPageViews,
    previoussearchesCount,
    loadingpreviousSearchesCount,
    previousshoppableClickCount,
    loadingpreviousshoppableClickCount,
  } = useCompanyAnalytics(
    selectedUser,
    startDate,
    endDate,
    previousStartDate,
    previousEndDate
  );
  return (
    <div className="d-side">
      <div className="details">
        <h1 className="dashboard-mobile-hide">
          {StaticVariables().COMPANY_ANALYTICS_TITLE}
        </h1>
        <div>
          <DropdownComponent items={items} setSelectedUser={setSelectedUser} />
        </div>
        <p className="mb-4 mt-4" style={{ fontSize: "larger" }}>
          Selected Creator -{" "}
          {selectedUser?.label === "All"
            ? liveCreators.join(", ")
            : selectedUser?.label}
        </p>
        <div>
          {" "}
          <DateSelector handleChange={updateDates} />
        </div>
        <p>Current Analytics</p>
        <div style={{ border: "1px solid grey" }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Card className="card-style">
                {loadingPageViews ? (
                  <Spin />
                ) : (
                  <p className="card-numbers">{pageViews}</p>
                )}
                <p className="card-text">Visits</p>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Card className="card-style">
                {loadingUniqueUsers ? (
                  <Spin />
                ) : (
                  <p className="card-numbers">{uniqueUsers}</p>
                )}
                <p className="card-text">Active Users</p>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Card className="card-style">
                {loadingSearchesCount ? (
                  <Spin />
                ) : (
                  <p className="card-numbers">{searchesCount}</p>
                )}
                <p className="card-text">Searches</p>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Card className="card-style">
                {loadingshoppableClickCount ? (
                  <Spin />
                ) : (
                  <p className="card-numbers">{shoppableClickCount}</p>
                )}
                <p className="card-text">Clicks on shoppable content</p>
              </Card>
            </Col>
          </Row>
          <br />
        </div>

        <hr />

        {daysNumber && daysNumber !== 0 ? (
          <>
            {showComparison ? (
              <button
                onClick={() => {
                  setShowComparison(false);
                }}
              >
                Hide Comparison
              </button>
            ) : (
              <button
              onClick={() => {
                setShowComparison(true);
              }}
            >
              View Comparison
            </button>
            )}
          </>
        ):""}
        {showComparison && (
          <>
            <p>Previous Analytics</p>
            <div style={{ border: "1px solid red" }}>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Card className="card-style">
                    {loadingpreviousPageViews ? (
                      <Spin />
                    ) : (
                      <p className="card-numbers">{previouspageViews}</p>
                    )}
                    <p className="card-text">Visits</p>
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Card className="card-style">
                    {loadingpreviousUniqueUsers ? (
                      <Spin />
                    ) : (
                      <p className="card-numbers">{previousuniqueUsers}</p>
                    )}
                    <p className="card-text">Active Users</p>
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Card className="card-style">
                    {loadingpreviousSearchesCount ? (
                      <Spin />
                    ) : (
                      <p className="card-numbers">{previoussearchesCount}</p>
                    )}
                    <p className="card-text">Searches</p>
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Card className="card-style">
                    {loadingpreviousshoppableClickCount ? (
                      <Spin />
                    ) : (
                      <p className="card-numbers">
                        {previousshoppableClickCount}
                      </p>
                    )}
                    <p className="card-text">Clicks on shoppable content</p>
                  </Card>
                </Col>
              </Row>
              <br />
            </div>{" "}
          </>
        )}
      </div>
    </div>
  );
};

export default CompanyAnalytics;
