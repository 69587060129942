import { Button } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { supabase } from "../../../../Config/SupabaseClient";
import { getUserData } from "../../../../SupabaseServices/UserDatabase";

const StoryUpload = (props: any) => {
  const { username } = props;
  const [storySelectedFiles, setStorySelectedFiles] = useState<any>([]);
  const [postDate, setPostDate] = useState<any>();

  const handleStoryFolderChange = async (event: any) => {
    const files = Array.from(event.target.files);
    const filteredFiles = files.filter((file: any) => file.size > 0);

    setStorySelectedFiles(filteredFiles);
  };
  const uploadStoryFolder = async () => {
    const uploadData: any = [];
    const csvFiles: any = [];
    const userData: any = await getUserData(username);
    let index = 1;

    let previousPostDate: any = null;
    console.log("ddssd", storySelectedFiles[0].lastModifiedDate, postDate);

    for (let [i, file] of storySelectedFiles.entries()) {
      const underscoreIndex = file.name.indexOf("_");
      const dotIndex = file.name.indexOf(".");

      if (underscoreIndex !== -1 && dotIndex !== -1) {
        const post_id = file.name.substring(0, underscoreIndex);
        const mediaType = file.type.split("/")[0];
        const mediaExt = file.type.split("/")[1];

        // Convert to Pacific Time Zone

        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        // Parse inputDate string into a Date object
        const dateParts = postDate.split("-");
        const year = parseInt(dateParts[0]);
        const month = parseInt(dateParts[1]) - 1; // Months are zero-indexed in JavaScript Date object
        const day = parseInt(dateParts[2]);

        // const parsedDate = new Date(Date.UTC(year, month, day));
        let parsedDate = new Date(year, month, day);
        // parsedDate.setHours(parsedDate.getHours() + 19);

        // Format the date into the desired format
        const formattedDate = `${parsedDate.toDateString()} ${
          parsedDate.getUTCHours() + 4
        }:${parsedDate.getUTCMinutes()}:${parsedDate.getUTCSeconds()} GMT+0530`;
        var dateTime = formattedDate;

        var pacificDateTime = new Date(dateTime);
        // pacificDateTime.setHours(pacificDateTime.getHours() - 7); // Pacific Time Zone is UTC-7

        if (previousPostDate) {
          // Add 10 minutes to previous post date
          previousPostDate.setMinutes(previousPostDate.getMinutes() - 1);
        } else {
          previousPostDate = pacificDateTime;
        }
        // const post_date = previousPostDate.toLocaleString('en-US', {timeZone: 'UTC', hour12: false}).replace(',', '');

        const post_date =
          previousPostDate.toISOString().slice(0, 19).replace("T", " ") +
          "-00:00";
        const datePart = post_date.split(" ")[0];
        // console.log(datePart);
        console.log(
          "date",
          i,
          datePart,
          post_date
          // pacificDateTime.toISOString().slice(0, 19).replace("T", " ") +
          //   "-00:00"
        );
        // console.log(previousPostDate.toISOString().slice(0, 19).replace("T", " ") + "-07:00");
        // index++; // Increment index for subsequent stories

        const { data, error } = await supabase.storage
          .from("stories")
          .upload(
            `${username}/${datePart}/${mediaType}${post_id}.${mediaExt}`,
            file,
            {
              contentType: `${file.type}`,
            }
          );
        const { data: url }: any = await supabase.storage
          .from("stories")
          .getPublicUrl(
            `${username}/${datePart}/${mediaType}${post_id}.${mediaExt}`
          );
        const { data: storedPost, error: storedPostError } = await supabase
          .from("content_instagram")
          .select("*")
          .eq("post_id", post_id)
          .eq("user_id", userData.user_id);

        if (storedPost && storedPost.length === 0) {
          const { data: content, error: contentError }: any = await supabase
            .from("content_instagram")
            .insert([
              {
                post_id,
                user_id: userData.user_id,
                post_date: post_date,
                caption: " ",
                media_url: url.publicUrl,
                type: "STORY",
                storage_url: url.publicUrl,
              },
            ])
            .select("*");
          const { data: content_new, error: newError } = await supabase
            .from("content_new")
            .insert([
              {
                post_id,
                user_id: userData.user_id,
                caption: " ",
                media_url: url.publicUrl,
                type: "STORY",
                platform: "instagram",
                post_date: post_date,
                raw_content_id: content[0].id,
                combined_text: " ",
              },
            ]);
          console.log("story upload", index, content_new, newError);
          // await new Promise(resolve => setTimeout(resolve, 30000));

        }
      } else {
        console.log("No match found.");
      }
      index += 1;
    }
    // );
    console.log("wait...");
    const time = storySelectedFiles.length * 1500;
    console.log(time);
    setTimeout(async () => {
      const thumbnailUrl = `https://dev.thesearchbubble.com/fetch/thumbnail`;
      // const thumbnailUrl = `http://localhost:3001/fetch/thumbnail`;

      const resThumbnail = await axios.post(
        thumbnailUrl,
        { id: userData.user_id, user: userData.username, from: "content" },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );
      console.log("res", resThumbnail.data);
      if (resThumbnail.data === "done") {
        console.log("Processing your data : Thumbnails, tags, embeddings");
        console.log("Please wait !!!");
        setTimeout(async () => {
          const { data: update, error } = await supabase
            .from("user")
            .update({ show_instagram: true, is_instagram_on: true })
            .eq("user_id", userData.user_id);
        }, storySelectedFiles.length * 1500);
        console.log("updates user");
      }
    }, time);
  };
  return (
    <div>
      {" "}
      <br />
      <h5>Upload Bulk Story</h5>
      <h6>Upload folder</h6>
      <input type="date" onChange={(e: any) => setPostDate(e.target.value)} />
      <input type="file" multiple onChange={handleStoryFolderChange} />
      <Button
        type="primary"
        className="primary-color"
        onClick={uploadStoryFolder}
      >
        Upload
      </Button>
    </div>
  );
};

export default StoryUpload;
